import '@glidejs/glide/dist/css/glide.core.min.css'; // Glide core styles
import Glide, { Autoplay, Breakpoints, Controls, Images, Swipe } from "@glidejs/glide/dist/glide.modular.esm";

export default function glide(selector) {
  return {
    init() {
      const glideElement = document.querySelector(selector);

      if (glideElement) {
        let settings;

        // Define different settings for specific selectors
        if (selector === '.glideHero') {
          settings = {
            type: 'carousel',
            startAt: 0,
            perView: 1.5,
            focusAt: 'center',
            gap: 5,
            autoplay: 4000,
            hoverpause: true,
            breakpoints: {
              640: { perView: 1 },
              768: { perView: 1.5 },
              1024: { perView: 1.75 },
              1280: { perView: 2 },
              1536: { perView: 2.25 },
            },
          };
        }

        // Initialize Glide with the appropriate settings
        new Glide(glideElement, settings).mount({ Autoplay, Breakpoints, Controls, Images, Swipe });
      }
    },
  };
}